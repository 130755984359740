import {
  createRouter,
  // createWebHashHistory,
  createWebHistory,
} from "vue-router";
import member from "@/middleware/member.js";
import backoffice from "@/middleware/backoffice.js";
import canSeePv from "@/middleware/canSeePv.js";
import canSeeGv from "@/middleware/canSeeGv.js";
import store from "@/store/index.js";
import axios from "@/axios.js";

const routes = [
  {
    path: "/",
    component: () => import("@/views/public/LayoutPublic"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/public/HomePage.vue"),
      },
      {
        path: "/cgv",
        name: "cgv",
        component: () => import("@/views/public/Cgv.vue"),
      },
      {
        path: "/confidentiality",
        name: "confidentiality",
        component: () => import("@/views/public/Confidentiality.vue"),
      },
      {
        path: "/confidentialities",
        name: "confidentialities",
        component: () => import("@/views/public/StarpinkConfidentiality.vue"),
      },
      {
        path: "/mentions",
        name: "mentions",
        component: () => import("@/views/public/StarpinkMentions.vue"),
      },
      {
        path: "/biography",
        name: "BiographyPageHome",
        component: () => import("@/views/public/BiographyPage.vue"),
      },
      {
        path: "/testimony",
        name: "TestimonyPageHome",
        component: () => import("@/views/public/TestimonyPage.vue"),
      },
    ],
  },

  {
    path: "/member/auth",
    component: () => import("@/views/auth/Layout"),
    children: [
      {
        path: "login",
        name: "MemberLogin",
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "recover-password",
        name: "RecoverPassword",
        component: () => import("@/views/auth/RecoverPassword"),
      },
      {
        path: "set-new-password",
        name: "SetNewPassword",
        component: () => import("@/views/auth/SetNewPassword"),
      },
    ],
  },
  {
    path: "/subsctiption",
    component: () => import("@/views/auth/Layout"),
    children: [
      {
        path: "redirect_payment_page",
        name: "redirect_payment_page",
        component: () => import("@/views/member/subscription/RedirectPage.vue"),
      },
    ],
  },
  {
    path: "/backoffice/auth",
    component: () => import("@/views/auth/Layout"),
    children: [
      {
        path: "login",
        name: "BackOfficeLogin",
        component: () => import("@/views/backoffice/auth/Login"),
      },
    ],
  },

  {
    path: "/member",
    component: () => import("@/views/member/LayoutMember.vue"),
    meta: { middleware: [member] },
    children: [
      {
        path: "",
        name: "MemberHomePage",
        component: () => import("@/views/member/HomePage.vue"),
      },
      {
        path: "registered",
        component: () => import("@/views/member/RegisteredPage.vue"),
      },
      {
        path: "payment",
        name: "PaymentPage",
        component: () => import("@/views/member/payment/PaymentPage.vue"),
      },
      {
        path: "settings",
        name: "SetttingsPage",
        component: () => import("@/views/member/SetttingsPage.vue"),
      },
      {
        path: "biography",
        name: "BiographyPage",
        component: () => import("@/views/member/BiographyPage.vue"),
      },
      {
        path: "testimony",
        name: "TestimonyPage",
        component: () => import("@/views/member/TestimonyPage.vue"),
      },
      {
        path: "my-account",
        name: "MyAccount",
        component: () => import("@/views/member/MyAccountPage.vue"),
      },
      {
        path: "page/successed",
        name: "PaymentSuccessed",
        component: () => import("@/views/member/payment/PaymentSuccessed.vue"),
      },
      {
        path: "page/failed",
        name: "PaymentFailed",
        component: () => import("@/views/member/payment/PaymentFailed.vue"),
      },
      {
        path: "voyance/pv",
        name: "PvPage",
        component: () => import("@/views/member/PvPage.vue"),
        // meta: { middleware: [canSeePv] },
      },
      // { path: 'products', name: 'ProductsPage', component: () => import('@/views/member/ProductsPage.vue'), meta : {middleware : [canSeePv]}},
      {
        path: "products",
        name: "MemberProductsPage",
        component: () => import("@/views/member/ProductsPage.vue"),
        // meta: { middleware: [canSeePv] },
      },
      // { path: 'voyance/gv', name: 'GvPage', component: () => import('@/views/member/GvPage.vue') },
      // { path: 'voyance/gv', name: 'GvPage', component: () => import('@/views/member/GvPage.vue'), meta : {middleware : [canSeeGv]} },
      {
        path: "voyance/gv",
        name: "GvPage",
        component: () => import("@/views/member/GvPage.vue"),
      },

      {
        path: "cgv",
        name: "CgVmember",
        component: () => import("@/views/member/CgvMember.vue"),
      },
      {
        path: "tchat",
        name: "Tchat",
        component: () => import("@/views/member/Tchat.vue"),
      },
      {
        path: "tchat_v2",
        name: "Tchat_v2",
        component: () => import("@/views/member/TchatV2.vue"),
      },
      {
        path: "my_subscription",
        name: "my_subscription",
        component: () => import("@/views/member/MySubscriptionPage.vue"),
      },
      {
        path: "abo_canceled",
        name: "abo_canceled",
        component: () => import("@/views/member/AboCanceledPage.vue"),
      },
      {
        path: "finalization",
        name: "finalization",
        component: () => import("@/views/member/FinalizationPayment.vue"),
      },
    ],
  },

  {
    path: "/backoffice/master",
    component: () => import("@/views/backoffice/master/LayoutMaster.vue"),
    meta: { middleware: [backoffice] },
    children: [
      {
        path: "home",
        name: "MasterHomePage",
        component: () => import("@/views/backoffice/master/HomePage.vue"),
      },

      {
        path: "leads",
        name: "LeadsPage",
        component: () => import("@/views/backoffice/master/LeadsPage.vue"),
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("@/views/backoffice/master/reports/Home.vue"),
        // children : [
        //   { path: 'affiliates', name: 'AffiliateReport', component: () => import('@/views/backoffice/master/reports/AffiliateReport.vue') },
        // ]
      },
      {
        path: "reports/affiliates",
        name: "AffReports",
        component: () =>
          import("@/views/backoffice/master/reports/AffiliateReport.vue"),
      },
      {
        path: "reports/affiliates/sid2",
        name: "AffReportsBySid2",
        component: () =>
          import("@/views/backoffice/master/reports/AffiliateReportBySid2.vue"),
      },

      {
        path: "lead/:lead",
        name: "LeadPage",
        component: () => import("@/views/backoffice/master/LeadPage.vue"),
      },
      {
        path: "website_user/:website_user/logins",
        name: "LoginsPage",
        component: () => import("@/views/backoffice/master/LoginsPage.vue"),
      },
      {
        path: "subscriptions",
        name: "SubscriptionsPage",
        component: () =>
          import("@/views/backoffice/master/SubscriptionsPage.vue"),
      },
      {
        path: "subscription/:subscription",
        name: "SubscriptionPage",
        component: () =>
          import("@/views/backoffice/master/SubscriptionPage.vue"),
      },
      {
        path: "transactions",
        name: "TransactionsPage",
        component: () =>
          import("@/views/backoffice/master/TransactionsPage.vue"),
      },
      {
        path: "conversation/:conversation",
        name: "ConversationPage",
        component: () =>
          import("@/views/backoffice/master/ConversationPage.vue"),
      },
      {
        path: "historyConsumptionCredit/:website_user",
        name: "HistoryConsumationCreditsPage",
        component: () =>
          import("@/views/backoffice/master/HistoryConsumationCreditsPage.vue"),
      },
      {
        path: "users",
        name: "UsersPage",
        component: () => import("@/views/backoffice/master/UsersPage.vue"),
      },
      {
        path: "sites",
        name: "SitesPage",
        component: () => import("@/views/backoffice/master/SitesPage.vue"),
      },
      {
        path: "website/:website/products",
        name: "ProductsPage",
        component: () => import("@/views/backoffice/master/ProductsPage.vue"),
      },
      {
        path: "website/:website/chaines",
        name: "ChainesPage",
        component: () => import("@/views/backoffice/master/ChainesPage.vue"),
      },
      {
        path: "website/:website/chain/:chain/chaines_messages",
        name: "ChainesMessagesPage",
        component: () =>
          import("@/views/backoffice/master/ChainesMessagesPage.vue"),
      },
      {
        path: "website/:website/chain/:chain/chaine_message/:chaine_message?",
        name: "ChaineMessagePage",
        component: () =>
          import("@/views/backoffice/master/ChaineMessagePage.vue"),
      },

      {
        path: "affiliates",
        name: "AffiliatesPage",
        component: () => import("@/views/backoffice/master/AffiliatesPage.vue"),
      },
      {
        path: "affiliate/:affiliate/leads",
        name: "AffiliateLeadsPage",
        component: () =>
          import("@/views/backoffice/master/AffiliateLeadsPage.vue"),
      },
      {
        path: "affiliate/:affiliate/comfroms",
        name: "AffiliateComfromsPage",
        component: () =>
          import("@/views/backoffice/master/AffiliateComfromsPage.vue"),
      },
      // { path: 'affiliate/:affiliate/comfrom/:comfrom', name: 'AffiliateComfromPage', component: () => import('@/views/backoffice/master/AffiliateComfromPage.vue') },
      {
        path: "ia",
        name: "MasterIa",
        component: () => import("@/views/backoffice/master/Ia.vue"),
      },
      {
        path: "website_user/:website_user/crm_email_chains",
        name: "crmEmailChain",
        component: () => import("@/views/backoffice/master/CrmEmailChainsPage.vue"),
      },
      {
        path: "email/:email",
        name: "Email",
        component: () => import("@/views/backoffice/master/email.vue"),
      },
      {
        path: "messages/vip_null",
        name: "messagesWithVipNull",
        component: () => import("@/views/backoffice/master/MessageswithVipNull.vue"),
      },
      {
        path: "messages/not_responded",
        name: "messagesNotResponded",
        component: () => import("@/views/backoffice/master/MessagesNotResponded.vue"),
      },
      {
        path: "message/:message/logs",
        name: "TchatMessageLogPage",
        component: () => import("@/views/backoffice/master/TchatMessageLogPage.vue"),
      },
      {
        path: "messages/sents_by_anim",
        name: "messagesSentsByAnim",
        component: () => import("@/views/backoffice/master/MessagesSentsByAnim.vue"),
      },

      {
        path: "stats/logins",
        name: "StatsLoginsPage",
        component: () => import("@/views/backoffice/master/stats/StatsLoginsPage.vue"),
      },
      {
        path: "stats/mailgun",
        name: "StatsMailgunPage",
        component: () => import("@/views/backoffice/master/stats/StatsMailgunPage.vue"),
      },
      {
        path: "stats/payment_page",
        name: "StatsPaymentPage",
        component: () => import("@/views/backoffice/master/stats/StatsPaymentPage.vue"),
      },
      {
        path: "messages/responded_by_ia",
        name: "messagesClientsRespondedByIa",
        component: () => import("@/views/backoffice/master/MessagesClientsRespondedByIa.vue"),
      },
      {
        path: "settings/timer_relanch_discussion",
        name: "timer_relanch_discussion",
        component: () => import("@/views/backoffice/master/SettingIntervalsTimes.vue"),
      },

      {
        path: "logs/google/recaptcha",
        name: "LogsGoogleRecaptcha",
        component: () => import("@/views/backoffice/master/LogsGoogleRecaptcha.vue"),
      },
    ],
  },

  {
    path: "/backoffice/animation",
    component: () => import("@/views/backoffice/animation/LayoutAnimation.vue"),
    children: [
      {
        path: "home",
        name: "HomePage",
        component: () => import("@/views/backoffice/animation/HomePage.vue"),
      },
      {
        path: "animators",
        name: "Animators",
        component: () => import("@/views/backoffice/animation/Animators.vue"),
      },
      {
        path: "logins",
        name: "logins",
        component: () => import("@/views/backoffice/animation/Logins.vue"),
      },
      {
        path: "conversation/list",
        name: "Conversation",
        component: () =>
          import("@/views/backoffice/animation/ConversationList.vue"),
      },
      {
        path: "conversation/:conversation",
        name: "Conversation",
        component: () =>
          import("@/views/backoffice/animation/Conversation.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () => import("../views/public/AboutPage.vue"),
  },
];

const router = createRouter({
  // history: process.env.NODE_ENV === 'production' ? createWebHashHistory() : createWebHistory(), // embeded_offline // WEB //todo
  //history: process.env.NODE_ENV === 'production' || process.env.IS_VERCEL === 'true' ? createWebHashHistory() : createWebHistory(),
  // history: createWebHistory(),
  history: createWebHistory(),
  // history : ,
  routes,
});

function nextFactory(context, middleware, index) {
  if (!store.state.website) {
    axios.get("website").then((resp) => {
      store.commit("SET_WEBSITE", resp.data.data);
    });
  }

  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  console.log("to--->->", to);

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
